/* sessionStorage key */
export const INVITE_COOPERATION_ID = 'invite_kol_id'; //确认合作协议ID
export const INVITE_COOPERATION_EMAIL = 'invite_kol_email'; //确认合作协议EMAIL
export const INVITE_KOL_ID = 'h5_kol_id'; // h5 id
export const INVITE_KOL_EMAIL = 'h5_kol_email'; // h5 email

export const platformMap: Record<string, string> = {
  TikTok: 'TikTok',
  Instagram: 'Ins',
  YouTube: 'YouTube',
  IG: 'IG',
  InsTikToks: 'TikTok',
};
