import useGA from '@/hooks/useGa';
import { GetServerSideProps } from 'next';
import PageCom from '@/page-components/login/Login';
import { MyAES } from '@/utils/aes';
import { INVITE_COOPERATION_EMAIL, INVITE_COOPERATION_ID, INVITE_KOL_EMAIL, INVITE_KOL_ID } from '@/page-components/invite/constants';
export const comeFrom = (kol_id?: any, kol_email?: any) => {
  if (sessionStorage.getItem(INVITE_KOL_ID)) {
    return '/invite/kol';
  } else if (sessionStorage.getItem(INVITE_COOPERATION_ID)) {
    return '/invite';
  } else if (kol_id && kol_email) {
    return 'email';
  } else {
    return '';
  }
};

const Login: React.FC<{ query: Record<string, any> }> = ({ query }) => {
  const { utm_ydk: kol_id, utm_yde: kol_email } = query;
  const from = comeFrom(kol_id, kol_email);
  useGA({
    pageTitle: '登录页',
    params: {
      from,
      kol_id: sessionStorage.getItem(INVITE_COOPERATION_ID) || sessionStorage.getItem(INVITE_KOL_ID) || (kol_id && MyAES.decrypt(String(kol_id))),
      kol_email:
        sessionStorage.getItem(INVITE_COOPERATION_EMAIL) ||
        sessionStorage.getItem(INVITE_KOL_EMAIL) ||
        (kol_email && MyAES.decrypt(String(kol_email))),
    },
  });
  return <PageCom query={query} />;
};
export const getServerSideProps: GetServerSideProps = async ({ query }) => {
  return { props: { query } };
};
export default Login;
