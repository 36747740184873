import CryptoJS, { AES } from 'crypto-js';

const key = CryptoJS.enc.Utf8.parse('12345678OVERSEAS');
const iv = CryptoJS.enc.Utf8.parse('OVERSEAS12345678');

export class MyAES {
  static encrypt(data: string): string {
    let srcs = CryptoJS.enc.Utf8.parse(data);
    let encrypted = CryptoJS.AES.encrypt(srcs, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.ciphertext.toString().toUpperCase();
  }

  static decrypt(data: string): string {
    let encryptedHexStr = CryptoJS.enc.Hex.parse(data);
    let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
    let decrypt = CryptoJS.AES.decrypt(srcs, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    return decryptedStr.toString();
  }
}

export function aes() {
  const secretKey = 'invitation-test';
  return {
    encrypt: (str: string) => AES.encrypt(str, secretKey).toString(),
    decrypt: (str: string) =>
      AES.decrypt(str, secretKey).toString(CryptoJS.enc.Utf8),
  };
}
