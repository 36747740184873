import { addGtagEvent, gaEventName } from '@/utils/ga';
import { useEffect } from 'react';
import useUser from './useUser';
import { RoleObject, UserRole } from '@/constants';
interface useGAPageViewProps {
  pageTitle?: string;
  depend?: string;
  role?: UserRole | null;
  params?: Record<string, any>;
}

const useGA = ({ pageTitle, params = {}, depend = '' }: useGAPageViewProps) => {
  const { email, role } = useUser();
  useEffect(() => {
    addGtagEvent({
      event: gaEventName.PAGE_VIEW,
      options: {
        page_title: pageTitle || '',
        role: role ? RoleObject[role] : '',
        page_location: window.location.href,
        page_path: window.location.pathname,
        referer: document.referrer || '',
        user_email: email,
        ...params,
      },
    });
  }, [depend]);
};

export default useGA;
